<template>
  <div :class="$style.root">
    <section :class="$style.gift_banner_section">
      <div :class="$style.gift_banner_wrapper">
        <AtomsConsumerLink
          :class="$style.gift_banner"
          url="/features/tential-gift"
          @click="trackBannerClick('top_page', '/features/tential-gift', 0, 'ギフトにおすすめ')"
        >
          <AtomsConsumerImage
            :class="$style.img"
            :src="`/images/components/organisms/page-bottom-fixed-contents/gift-banner-${
              !minScreenMd ? 'sp' : 'pc'
            }.jpg`"
            alt="ギフトにおすすめ"
          />
          <div :class="$style.banner_content">
            <p :class="$style.banner_content_title">ギフトにおすすめ</p>
            <p :class="$style.banner_content_description">
              がんばる姿を、後押しできる。<br />24時間365日、体をサポートする。<br />TENTIALの、ささえるギフトです。
            </p>
          </div>
        </AtomsConsumerLink>
      </div>
    </section>

    <section :class="$style.shop_section">
      <AtomsConsumerImage
        :src="`/images/components/organisms/page-bottom-fixed-contents/shop-${!minScreenMd ? 'sp' : 'pc'}.jpg`"
        alt="店舗一覧"
        :class="$style.img"
      />
      <div :class="$style.shop_section_content">
        <div :class="$style.shop_section_content_wrapper">
          <div :class="$style.shop_section_header">
            <MoleculesConsumerHeading title="店舗一覧" variant="sub" align="left" :class="$style.header_text" />
            <p :class="$style.sub_header_text">取扱い商品についてのお問い合わせは<br />直接店舗にご連絡ください。</p>
          </div>
          <div :class="$style.shop_links">
            <div :class="$style.region_link_wrapper">
              <AtomsConsumerLink
                v-for="region in regions"
                :key="region.name"
                :url="region.url"
                :class="$style.region_link"
              >
                <p :class="$style.region_link_text">{{ region.name }}</p>
                <AtomsConsumerImage :class="$style.arrow_right" src="/images/icon/arrow-down.svg" alt="arrow-right" />
              </AtomsConsumerLink>
            </div>
            <div :class="$style.search_button_wrapper">
              <AtomsConsumerLink url="/shop?geolocation=true">
                <MoleculesConsumerButtonWithArrowRight variant="secondary" rounded>
                  お近くの店舗を探す
                </MoleculesConsumerButtonWithArrowRight>
              </AtomsConsumerLink>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section :class="$style.product_browsing_history_section">
      <PagePartsProductBrowsingHistory
        :class="$style.product_browsing_history"
        title="閲覧した商品"
        button-variant="primary"
      />
    </section>

    <section v-if="isTopPage" :class="$style.news_list_section">
      <PagePartsNewsList :news-group-id="NEWS_GROUP_ID_FOR_TOP" />
    </section>
  </div>
</template>

<script setup lang="ts">
import { PRODUCTION_NEWS_GROUP_ID, DEV_NEWS_GROUP_ID } from "~/const/top_page"

const config = useRuntimeConfig()
const route = useRoute()
const { trackBannerClick } = useGtagEvent()

defineProps<{
  minScreenMd: boolean
}>()
const regions = [
  {
    name: "関東",
    url: "/shop?page=1&prefecture=茨城県&prefecture=栃木県&prefecture=群馬県&prefecture=埼玉県&prefecture=千葉県&prefecture=東京都&prefecture=神奈川県",
  },
  {
    name: "北海道",
    url: "/shop?page=1&prefecture=北海道",
  },
  {
    name: "東北",
    url: "/shop?page=1&prefecture=青森県&prefecture=岩手県&prefecture=宮城県&prefecture=秋田県&prefecture=山形県&prefecture=福島県",
  },
  {
    name: "甲信越",
    url: "/shop?page=1&prefecture=山梨県&prefecture=長野県&prefecture=新潟県",
  },
  {
    name: "北陸",
    url: "/shop?page=1&prefecture=富山県&prefecture=石川県&prefecture=福井県",
  },
  {
    name: "東海",
    url: "/shop?page=1&prefecture=愛知県&prefecture=岐阜県&prefecture=静岡県&prefecture=三重県",
  },
  {
    name: "関西",
    url: "/shop?page=1&prefecture=滋賀県&prefecture=京都府&prefecture=大阪府&prefecture=兵庫県&prefecture=奈良県&prefecture=和歌山県",
  },
  {
    name: "中国",
    url: "/shop?page=1&prefecture=鳥取県&prefecture=島根県&prefecture=岡山県&prefecture=広島県&prefecture=山口県",
  },
  {
    name: "四国",
    url: "/shop?page=1&prefecture=徳島県&prefecture=香川県&prefecture=愛媛県&prefecture=高知県",
  },
  {
    name: "九州",
    url: "/shop?page=1&prefecture=福岡県&prefecture=佐賀県&prefecture=長崎県&prefecture=熊本県&prefecture=大分県&prefecture=宮崎県&prefecture=鹿児島県&prefecture=沖縄県",
  },
]

/**
 * トップページのニュース欄掲載用のグループID
 */
const NEWS_GROUP_ID_FOR_TOP = config.public.APP_ENV === "production" ? PRODUCTION_NEWS_GROUP_ID : DEV_NEWS_GROUP_ID

const isTopPage = computed(() => route.path === "/")
</script>

<style scoped module lang="scss">
$shop-section-bg-color: #e8f1fe;
.root {
  background-color: $primary-10;
  padding: 3.75rem 0;
  z-index: 5;
  .img {
    width: 100%;
    object-fit: cover;
  }
  .gift_banner_section {
    contain-intrinsic-width: auto 425px;
    margin-bottom: 3rem;
    background-color: $primary-10;
    @include md {
      margin-bottom: 3.75rem;
    }
    .gift_banner_wrapper {
      @include container-lg;
    }
    .gift_banner {
      display: block;
      position: relative;
      .img {
        border-radius: 4px;
        max-width: 1360px;
      }
      .banner_content {
        width: 85%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 28.5px;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;
        color: $white;
        text-align: center;
        @include lg {
          bottom: 24.2%;
        }
        .banner_content_tag {
          padding: 0.25rem 0.5rem;
          border: 1px solid $white;
          font-size: $font-size-12;
          line-height: 1.7;
        }
        .banner_content_title {
          font-size: $font-size-28;
          line-height: 1.5;
          white-space: pre-line;
          @include md {
            font-size: clamp(1.25rem, 0.5rem + 1.56vw, 1.75rem);
          }
        }
        .banner_content_description {
          font-size: font-size-14;
          white-space: pre-line;
          @include md {
            font-size: clamp(0.625rem, 0.063rem + 1.17vw, 1rem);
          }
        }
      }
    }
  }
  .shop_section {
    contain-intrinsic-width: auto 955px;
    background: $shop-section-bg-color;
    .img {
      aspect-ratio: 375 / 190;
      @include md {
        aspect-ratio: 1600 / 540;
      }
    }
    .shop_section_content {
      @include container-lg;
      padding-top: 1.5rem;
      padding-bottom: 3.75rem;
      width: 100%;
      @include md {
        padding-top: 3.75rem;
        padding-bottom: 6.25rem;
      }
      .shop_section_content_wrapper {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding: 1.5rem;
        @include md {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 0;
          padding: 1.5rem 0;
          max-width: 1200px;
          margin: 0 auto;
        }
        .shop_section_header {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          .header_text {
            line-height: 1.5;
          }
          .sub_header_text {
            line-height: 1.7;
          }
        }
        .shop_links {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          @include md {
            gap: 3rem;
          }
          .region_link_wrapper {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .region_link {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-bottom: 0.25rem;
              border-bottom: 1px solid $primary-20;
              .arrow_right {
                transform: rotate(-90deg);
                max-width: 15px;
              }
              .region_link_text {
                font-weight: 500;
                line-height: 1.5;
              }
            }
          }
          .search_button_wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            @include md {
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
  .product_browsing_history {
    contain-intrinsic-width: auto 600px;
    margin-top: 3.75rem;
  }
  .news_list_section {
    contain-intrinsic-width: auto 400px;
    margin-top: 3.75rem;
    @include md {
      margin-top: 6.25rem;
    }
  }
}
</style>
